import React, { useState, useEffect } from 'react';
import {translations as translations_esp} from '../../texts/esp/home-translations'
import { makeStyles } from '@material-ui/styles';
import { alpha, Grid, Icon, Typography } from '@material-ui/core';
//import {translations as translations_en} from '../../texts/esp/home-translations'
import logo from '../../assets/ubelens-logo.png'
import patient from '../../assets/patient.png'
import optometrist from '../../assets/optometrist.png'

const HomeView = ({user, history}) => {

    const content = translations_esp
    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <div className={classes.container}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant='h3' className={classes.title}>Bienvenido a</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.logo_container}>
                            <img src={logo} alt='' className={classes.logo}/>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.button_container}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <SmartButton title='Soy paciente' image={patient} url='https://devpacientes.ubelens.com/' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SmartButton title='Soy óptica/optómetra' image={optometrist} url='https://devadmin.ubelens.com/'/>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:32,
        marginTop:40
    },
    container:{
        width:1000,
        margin:'auto',
        color:'white'

    },
    title:{
        color:'white',
        textAlign:'center',
        fontWeight:500
    },
    subtitle:{
        color:'white',
        fontWeight:400,
        fontSize:'1.25rem',
        marginTop:40
    },
    logo_container:{
        //background:'white',
        width:200,
        borderRadius:40,
        margin:'auto',
        margin:'auto'
    },
    logo:{
        width:'100%',
       
    },
    button_container:{
        width:600,
        margin:'auto',
        marginTop:32
    }
}))
 
export default HomeView;



const SmartButton = ({title, image, url}) => {

    const classes = useSmartButtonStyles()

    return(
        <a href={url} className={classes.a} >
            <div className={classes.root}>
                <div className={classes.container}>
                    <Grid container direction='column' alignItems='center' spacing={2}>
                        <Grid item>
                            <img src={image} alt='' className={classes.icon}/>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title} align='center'>{title}</Typography>
                        </Grid>
                        
                    </Grid>
                </div>
            </div>
        </a>
        
    )
}

const useSmartButtonStyles = makeStyles(theme => ({
    a:{
        textDecoration:'none'
    },
    root:{
        border:'1px solid #FFF',
        //background:'red',
        margin:16,
        borderRadius:20,
        width:'100%',
        paddingTop:'100%',
        position:'relative',
        cursor:'pointer',
        transition:'background 0.4s linear',
        '&:hover':{
            background:alpha('#FFF',0.15),
            transition:'background 0.4s linear'
        }
    },
    title:{
        color:'white'
    },
    container:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    icon:{
        width:100
    }
}))