import React, {useState, useEffect} from 'react';
import { AppBar, makeStyles, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {translations} from '../../../texts/esp/general-translations'

import cx from 'classnames'
import { grey } from '@material-ui/core/colors';
import { DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { onClearMemory } from '../../../shared/utility';

const Topbar = ({user, history, is_auth, cart, onUpdateSignInModal, onUpdateUserData, onUpdateAuthStatus}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const trigger = useScrollTrigger({disableHysteresis:true, threshold:100})


    const onItemSelected = async(url) => {
        switch (url) {
            case '/signin':
                onUpdateSignInModal(true)
                break;
            case '/logout':
                onUpdateUserData(null)
                onUpdateAuthStatus(false)
                onClearMemory()
                history.push('/')
                break;
        
            default:
                history.push(url)
                break;
        }
    }

    const current_path = history.location.pathname

    let _trigger = trigger || current_path !== '/'
    return ( 
        <AppBar className={cx({
            [classes.appbar]:true,
            [classes.appbar_mono]:!_trigger
        })}>
            <div className={classes.root}>
                Aqui va el topbar
            </div>
        </AppBar>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:1400,
        
        padding:'12px 0px',
        margin:'auto',
        boxSizing:'border-box',
        position:'relative',
        [theme.breakpoints.only('lg')]:{
            width:1200,
        },
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:'12px 0px',
            //height:300
        },
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:'0px 16px'
        },
    },
    button:{
        background:'white',
        color:'black',
        '&:hover':{
            background:'white',
        }
    },
    appbar:{
        background:'white',
        background:grey[200],
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        height:76,
        color:'grey',
    },
    appbar_mono:{
        background:'transparent',
        background:grey[200],
        boxShadow:'none',
        color:'grey',
    },
    logo:{
        width:48
    },
    searchbar:{
        position:'absolute',
        top:24,
        left:'50%',
        transform:'translateX(-50%)'
    }
}))

const mapStateToProps = state => {
    return {
        user:state.user,
        is_auth:state.is_auth,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSignInModal: (signin_modal) => dispatch({type:actionTypes.MODAL_UPDATE_SIGNIN, signin_modal}),
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        

    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)( Topbar));
