import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import HomeView from '../views/HomeView/HomeView'

const DashboardRouter = props => {
    return(
        <Switch>
            <Route exact path='/' component={HomeView} />
            <Redirect to='/' />
        </Switch>
    )
}

export default DashboardRouter

