import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import cx from 'classnames'
import Footer from './components/Footer';
import useWebsiteLayout from './useWebsiteLayout';
import ErrorModal from '../../components/Modals/ErrorModal';


const WebsiteLayout = ({history, onUpdateURL, blur, user, is_auth, error, 
    onUpdateUserData, onUpdateAuthStatus, onUpdateCart, onUpdateError}) => {

    const classes = useStyles()
    const theme = useTheme()

    const {loading, sending, actions } = useWebsiteLayout({onUpdateUserData, onUpdateAuthStatus, onUpdateCart})
    

    return ( 
        <div className={cx({
            [classes.root]:true,
            [classes.root_blur]:blur
        })}>
            <ErrorModal open={Boolean(error)} message={error ? error.message : null}
            onClose={() => onUpdateError(null)}/>
            <main className={classes.main} >
                <WebsiteRouter/>
            </main>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        minHeight:'100vh',
        overflowY:'auto',
        filter:'none'

    },
    root_blur:{
        filter:'blur(3px)'
    },
    main:{
        //marginLeft:150,
        //padding:16,
        overflowY:'auto'
        
    }
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        ip_address:state.ip_address,
        blur: state.blur,
        user: state.user,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateError: (error) => dispatch({type:actionTypes.SYS_UPDATE_ERROR, error})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout);

